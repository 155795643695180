import React, { useRef, useEffect, useMemo } from 'react';
// components
import MarkdownDisplay from 'Component/MarkdownDisplay';
// utils
import { getContentSlides, getContentSectionsBySlide } from 'Util/Post';
import { getRenderSectionsAndIndex } from './utils';
//
import './styles.scss';

function TemplateReelSlide({ slides, renderIndex }) {
  const { sections, validIndex } = useMemo(() => {
    return getRenderSectionsAndIndex(slides, renderIndex);
  }, [slides, renderIndex]);

  return (
    <div
      className="template-slide template-reel"
      data-index={renderIndex}
      data-screenshot={`${renderIndex}.png`}
    >
      <div className="template-reel-body">
        {sections.map((section, index) => {
          return (
            <div
              key={section}
              className="template-reel-body-section"
              style={{ opacity: index > validIndex ? 0 : 1 }}
            >
              <MarkdownDisplay>{section}</MarkdownDisplay>
            </div>
          );
        })}
      </div>
      <div className="template-reel-footer">
        Like this sharing? Please give us a ❤️ + 👍!
      </div>
    </div>
  );
}

export default React.memo(TemplateReelSlide);
