import React, { useCallback, useState, useRef, useEffect } from 'react';
// components
import Terminal from 'Component/TerminalModal';
import { Page } from 'Component/Page';
import Header from 'Component/Header';
import Card from 'Component/Card';
import CodeEditor from 'Component/CodeEditor';
import EditorProps from './Component/EditorProps';
import EditorHeader from './Component/EditorHeader';
import EditorPreview from './Component/EditorPreview';
import BrowserModal from 'Component/BrowserModal';
// constatns
import { TEMPLATES } from 'Template';
// utils
import Request from 'Util/Request';
import firebase from 'Util/Firebase';
import { getContentSlides } from 'Util/Post';
import {
  getActiveSlideByLineNumber,
  getDocumentTitleFromMarkdown,
} from './utils';
import { download } from 'Util/Storage';
import { call, isAvailable } from 'Util/Agent';
// styles
import './styles.scss';

class EditorPage extends React.PureComponent {
  constructor(props) {
    super(props);
    //
    this.state = {
      post: {
        ...props.post,
      },
      hasPendingChanges: false,
    };
  }

  handleSaveClick = async () => {
    const post = {
      ...this.state.post,
      updateTime: Date.now(),
    };
    // trigger post save api
    await firebase
      .firestore()
      .collection('tiny-post')
      .doc(post.id)
      .update(post);
    // trigger template's onSave event
    const currentTemplate = TEMPLATES.find(
      (template) => template.id === post.template,
    );
    if (currentTemplate.onSave) {
      await currentTemplate.onSave({ post });
    }
    // reset save button status
    this.setState({
      post,
      hasPendingChanges: false,
    });
  };

  handlePostChange = (post) => {
    this.setState({
      hasPendingChanges: true,
      post: {
        ...post,
        content: this.state.post.content,
      },
    });
  };

  handleEditorContentChange = (content) => {
    this.setState({
      hasPendingChanges: true,
      post: {
        ...this.state.post,
        content,
      },
    });
    // update
    document.title = getDocumentTitleFromMarkdown({ content });
  };

  handleEditorActiveLineChange = (activeLineNumber) => {
    try {
      const index = getActiveSlideByLineNumber({
        content: this.state.content,
        activeLineNumber,
      });
      this.refs.viewer
        .querySelectorAll(`.template-slide`)
        [index]?.scrollIntoView();
    } catch (ex) {}
  };

  handleDeleteDocumentClick = async () => {
    // final confirmation
    const isConfirmed = confirm('Are you sure to remove this document?');
    // confirm to delete
    if (isConfirmed) {
      // delete from firestore
      await firebase
        .firestore()
        .collection('tiny-post')
        .doc(this.state.post.id)
        .delete();
      // close current window
      window.close();
    }
  };

  renderButton() {
    //
    const { post } = this.state;
    //
    const isAgentAvaiable = isAvailable(),
      currentTemplate = TEMPLATES.find(
        (template) => template.id === post.template,
      );
    // has pending changes
    // - Y: show save button
    // - N: show build button
    if (this.state.hasPendingChanges) {
      return (
        <button
          className="w-full py-3 px-4 rounded bg-blue-500 text-white hover:bg-blue-600"
          onClick={this.handleSaveClick}
        >
          <span className="mr-2 icon icon-save"></span>Save Document
        </button>
      );
    } else if (!isAgentAvaiable) {
      return (
        <button className="w-full py-3 px-4 rounded bg-gray-300 text-white">
          <span className="mr-2 icon icon-cloud-off"></span>Agent Offline
        </button>
      );
    } else if (currentTemplate?.renderBuildButton) {
      return currentTemplate.renderBuildButton({
        post,
      });
    } else {
      return (
        <button className="w-full py-3 px-4 rounded bg-gray-300 text-white">
          <span className="mr-2 icon icon-save"></span>Save Document
        </button>
      );
    }
  }

  render() {
    const { post } = this.state;

    return (
      <>
        <Page className="flex items-stretch h-full cursor-default">
          <div className="mr-4 w-52 overflow-y-auto">
            <div className="h-16 pt-2">
              <img
                className="w-20 catalog-logo"
                src="https://firebasestorage.googleapis.com/v0/b/tinyfrontend.appspot.com/o/tiny-post%2Fwebsite%2Flogo.png?alt=media&token=6aaab468-f3de-4394-ba92-39bb2b65e4bf"
                alt=""
              />
            </div>
            <Card>{this.renderButton()}</Card>
            <Card>
              <EditorHeader post={post} onChange={this.handlePostChange} />
            </Card>

            {post.status !== 'published' ? (
              <button
                className="mt-4 w-full py-2 text-sm text-center text-red-500 hover:text-red-300"
                onClick={this.handleDeleteDocumentClick}
              >
                <span className="icon icon-trash-2"></span> Remove Document
              </button>
            ) : null}
          </div>
          <div className="mr-4 rounded-lg flex-1 overflow-hidden">
            <CodeEditor
              template={post.template}
              language="markdown"
              storage="tiny-post/images"
              value={post.content}
              onSave={this.handleSaveClick}
              onChange={this.handleEditorContentChange}
              onActiveLineChange={this.handleEditorActiveLineChange}
            />
          </div>
          <div ref="viewer" className="editor-body-viewer rounded-lg h-full">
            <EditorPreview post={post} />
          </div>
        </Page>
        <BrowserModal />
      </>
    );
  }
}

export default function EditorPageWithFetcher({ postId, ...rest }) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection('tiny-post')
      .doc(postId)
      .get()
      .then(firebase.utils.parsePost)
      .then(setPost);
  }, []);

  // update document title
  useEffect(() => {
    document.title = getDocumentTitleFromMarkdown(post);
  }, [post?.content]);

  return post ? <EditorPage {...rest} post={post} /> : null;
}
