import React, { useCallback, useEffect, useMemo, useState } from 'react';

import firebase from 'Util/Firebase';
// constants
import { PREDEFINED_COLORS, PREDEFINED_GRID_RATIOS } from './constants';
// components
import { Page, PageNavigator } from 'Component/Page';
import Card from 'Component/Card';
import CodeModal from './Component/CodeModal';
import {
  Excalidraw,
  MainMenu,
  serializeAsJSON,
  exportToCanvas,
} from '@excalidraw/excalidraw';
// utils
import throttle from 'lodash.throttle';
import { call } from 'Util/Agent';
import { upload, getDownloadURL, getStoragePath } from 'Util/Storage';
import { canvasToBlob } from './utils';
// styles
import './styles.scss';

function ExcalidrawPage({ excalidrawId, initialData }) {
  const [excalidrawAPI, setExcalidrawAPI] = useState(null),
    [doneButtonText, setDoneButtonText] = useState('Done'),
    [showCodeModal, setShowCodeModal] = useState(false),
    [excalidrawBackgroundColor, setExcalidrawBackgroundColor] = useState(
      initialData.appState.viewBackgroundColor,
    ),
    [stageRatio, setStageRatio] = useState('');

  const handleCloseClick = useCallback(() => {
    window.top.closeBrowserModal();
  }, []);

  useEffect(() => {
    if (
      excalidrawAPI &&
      excalidrawBackgroundColor.match(/^#[0-9A-Fa-f]{6}([0-9A-Fa-f]{2})?$/)
    ) {
      excalidrawAPI.updateScene({
        appState: {
          viewBackgroundColor: excalidrawBackgroundColor,
        },
      });
    }
  }, [excalidrawBackgroundColor, excalidrawAPI]);

  const handleSaveClick = useCallback(async () => {
    //
    setDoneButtonText('Saving...');
    //
    const [elements, appState, files] = [
      excalidrawAPI.getSceneElements(),
      excalidrawAPI.getAppState(),
      excalidrawAPI.getFiles(),
    ];
    // save orginal drawing file
    const excalidrawData = JSON.parse(
        serializeAsJSON(elements, appState, files, 'local'),
      ),
      codeSnippetFile = excalidrawAPI.getFiles().codeSnippet;
    if (codeSnippetFile) {
      excalidrawData.files.codeSnippet = codeSnippetFile;
    }
    // upload to firestore
    await upload({
      path: getStoragePath({
        type: 'excalidraw',
        filename: `${excalidrawId}.excalidraw`,
      }),
      blob: new Blob([JSON.stringify(excalidrawData)], {
        type: 'application/json',
      }),
    });
    // save export webp image
    const canvas = await exportToCanvas({
      elements,
      appState,
      files,
      exportPadding: 20,
      exportBackground: false,
      maxWidthOrHeight: 1080,
    });
    await upload({
      path: getStoragePath({
        type: 'image',
        filename: `${excalidrawId}.webp`,
      }),
      blob: await canvasToBlob(canvas),
    });
    //
    setDoneButtonText('Done');
    // close current page
    window.top.closeBrowserModal();
  }, [excalidrawAPI]);

  // renders
  const renderModalButtons = useCallback(() => {
    return (
      <>
        <button
          className="mr-1 rounded-lg bg-gray-200 py-2 px-4 hover:bg-gray-100"
          onClick={handleCloseClick}
        >
          Cancel
        </button>
        <button
          className="rounded-lg py-2 px-4 bg-blue-500 text-white hover:bg-blue-600"
          onClick={handleSaveClick}
        >
          <span className="mr-2 icon icon-save"></span>
          {doneButtonText}
        </button>
      </>
    );
  }, [doneButtonText, handleCloseClick, handleSaveClick]);

  const renderMainMenuButton = ({ title, value, onClick, selected }) => {
    return (
      <button
        className="block rounded border h-8"
        style={{
          borderColor: value === selected ? '#e0dfff' : '#eee',
          background: value === selected ? '#e0dfff' : '#fff',
        }}
        onClick={onClick}
      >
        {title}
      </button>
    );
  };

  const renderMainMenu = () => {
    return (
      <MainMenu>
        <MainMenu.Group title="Grid">
          <MainMenu.ItemCustom>
            <div className="mt-2 w-48 grid grid-cols-2 gap-2">
              {PREDEFINED_GRID_RATIOS.map(({ title, value }) => {
                return renderMainMenuButton({
                  title,
                  value,
                  onClick: () => setStageRatio(value),
                  selected: stageRatio,
                });
              })}
            </div>
          </MainMenu.ItemCustom>
        </MainMenu.Group>
        <MainMenu.Group title="Background">
          <MainMenu.ItemCustom>
            <div>
              <input
                type="text"
                className="block w-full rounded border border-gray-200 h-8 px-2"
                value={excalidrawBackgroundColor}
                onChange={(e) => setExcalidrawBackgroundColor(e.target.value)}
              />
              <div className="mt-2 w-48 grid grid-cols-2 gap-2">
                {PREDEFINED_COLORS.map(({ title, titleColor, color }) => {
                  return renderMainMenuButton({
                    title,
                    value: color,
                    onClick: () => setExcalidrawBackgroundColor(color),
                    selected: excalidrawBackgroundColor,
                  });
                })}
              </div>
            </div>
          </MainMenu.ItemCustom>
        </MainMenu.Group>
        <MainMenu.Group title="Quick Links">
          <MainMenu.Item
            icon={<span className="icon icon-file-text" />}
            onSelect={() => setShowCodeModal(true)}
          >
            Code Board
          </MainMenu.Item>
          <MainMenu.ItemLink
            icon={<span className="icon icon-image" />}
            href="https://storyset.com/"
          >
            StorySet Library
          </MainMenu.ItemLink>
        </MainMenu.Group>
      </MainMenu>
    );
  };

  const renderCodeModal = () => {
    const { language = 'javascript', codeSnippet = '' } = JSON.parse(
      atob(excalidrawAPI.getFiles()?.codeSnippet?.dataURL ?? 'e30='),
    );

    return (
      <CodeModal
        language={language}
        codeSnippet={codeSnippet}
        onDone={(codeSnippet) => {
          excalidrawAPI.addFiles([
            {
              id: 'codeSnippet',
              dataURL: btoa(JSON.stringify(codeSnippet)),
              mimeType: 'application/octet-stream',
              created: Date.now(),
            },
          ]);
          setShowCodeModal(false);
        }}
        onCancel={() => setShowCodeModal(false)}
      />
    );
  };

  return (
    <Page>
      <div className="rounded-lg bg-gray-100 overflow-hidden w-full h-full relative">
        <Excalidraw
          initialData={initialData}
          excalidrawAPI={setExcalidrawAPI}
          renderTopRightUI={renderModalButtons}
        >
          {renderMainMenu()}
        </Excalidraw>
        {stageRatio ? (
          <div
            className="excalidraw-stage-ratio"
            style={{
              aspectRatio: stageRatio,
            }}
          ></div>
        ) : null}
        {showCodeModal ? renderCodeModal() : null}
      </div>
    </Page>
  );
}

export default function ({ excalidrawId }) {
  //
  const [initialData, setInitialData] = useState(null);
  //
  useEffect(() => {
    call(`/api/excalidraw/${excalidrawId}`)
      .then(setInitialData)
      .catch(() => {
        setInitialData({});
      });
  }, [excalidrawId]);
  //
  useEffect(() => {
    // update page title
    document.title = 'Excalidraw - TinyFrontend';
    // listen to ESC press
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        openBrowserModal('');
      }
    });
  }, []);
  console.log('>>> ', initialData);
  return initialData ? (
    <ExcalidrawPage excalidrawId={excalidrawId} initialData={initialData} />
  ) : null;
}
