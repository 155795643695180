import React from 'react';
import MarkdownDisplay from 'Component/MarkdownDisplay';
// images
import BackgroundImage from './Asset/Image/Background.png';
// styles
import './styles.scss';

export default function TemplateFirstFinal({ index, count, children }) {
  return (
    <div
      className="template-default-last"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
      data-screenshot={`${index}.png`}
    ></div>
  );
}
