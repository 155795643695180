import * as monaco from 'monaco-editor/esm/vs/editor/editor.main.js';
import md5 from 'md5';
import firebase from 'Util/Firebase';
// utils
import { openBrowserModal } from 'Component/BrowserModal';
import { getDraftQuizAnswerPrompt } from './utils';

export function addAction(
  editor,
  options: {
    template: string;
    onSave: Function;
    storage: string;
    editorAI: any;
  },
) {
  const { editorAI } = options;
  // post - save post
  editor.addAction({
    id: 'action-post-save',
    label: 'Save',
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'post',
    contextMenuOrder: 2.1,
    run: function (ed) {
      options.onSave();
    },
  });
  // post - export post
  editor.addAction({
    id: 'action-post-export',
    label: 'Export',
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyE],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'post',
    contextMenuOrder: 2.2,
    run: function (ed) {},
  });
  // page - page break button
  editor.addAction({
    id: 'action-page-break',
    label: 'Page Break',
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'page',
    contextMenuOrder: 1.5,
    run: function (ed) {
      editor.trigger('keyboard', 'type', {
        text: `\n\n----------\n\n`,
      });
    },
  });
  // attachment - image
  editor.addAction({
    id: 'action-insert-attachment',
    label: 'Insert Image',
    keybindings: [
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyI,
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyO,
    ],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'attachment',
    contextMenuOrder: 1.5,
    run: async function (ed) {
      const url = `https://cms.tinyfrontend.com/?/excalidraw/${md5(
        Date.now(),
      )}`;
      openBrowserModal(url);
      editor.trigger('keyboard', 'type', {
        text: `<Excalidraw title="" url="${url}" />\n`,
      });
    },
  });
  // attachment - youtube
  editor.addAction({
    id: 'action-insert-youtube',
    label: 'Insert YouTube',
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyY],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'attachment',
    contextMenuOrder: 1.5,
    run: async function (ed) {
      editor.trigger('keyboard', 'type', {
        text: `<YouTube id="VIDEO_ID" />`,
      });
    },
  });
  // ai - write content
  editor.addAction({
    id: 'action-ai-write-content',
    label: '🌟 Run Command',
    keybindings: [
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyA,
    ],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'ai',
    contextMenuOrder: 1.1,
    run: async function (ed) {
      const currentSelection = editor.getSelection(),
        currentContent = editor.getModel().getValueInRange(currentSelection);
      //
      const message = `
        Please help me to write the content based on the following information:

        ${currentContent}
      `;
      //
      const result = await editorAI
        .sendMessage(message)
        .then((result) => {
          return result.response.text();
        })
        .catch((ex) => {
          console.error(ex);
        });
      //
      editor.executeEdits('replace-selection', [
        {
          range: currentSelection,
          text: result,
        },
      ]);
    },
  });
  // ai - rewrite content
  editor.addAction({
    id: 'action-ai-rewrite-sentence',
    label: '🌟 Rewrite Selected Sentences',
    keybindings: [
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyR,
    ],
    precondition: null,
    keybindingContext: null,
    contextMenuGroupId: 'ai',
    contextMenuOrder: 1.2,
    run: async function (ed) {
      const currentSelection = editor.getSelection(),
        currentContent = editor.getModel().getValueInRange(currentSelection);
      //
      const message = `
        Based on the provided context, please help me to rewrite the following sentences:
        
        ${currentContent}
      `;
      //
      const result = await editorAI
        .sendMessage(message)
        .then((result) => {
          return result.response.text();
        })
        .catch((ex) => {
          console.error(ex);
        });
      //
      editor.executeEdits('replace-selection', [
        {
          range: currentSelection,
          text: result,
        },
      ]);
    },
  });
  // ai - website post
  if (options.template === 'website') {
    // ai - generate outlines
    editor.addAction({
      id: 'action-ai-generate-post-outlines',
      label: '🌟 Generate Post Outlines',
      precondition: null,
      keybindingContext: null,
      contextMenuGroupId: 'ai-post',
      contextMenuOrder: 1.3,
      run: async function (ed) {
        const currentSelection = editor.getSelection(),
          currentContent = editor.getModel().getValueInRange(currentSelection);
        //
        const message = `
        I'm writing a blog post about "${currentContent}".
        Please help me to generate the outlines of this post.
        The heading tag should starting from Heading1 and accordingly.
      `;
        //
        const result = await editorAI
          .sendMessage(message)
          .then((result) => {
            return result.response.text();
          })
          .catch((ex) => {
            console.error(ex);
          });
        //
        editor.executeEdits('replace-selection', [
          {
            range: currentSelection,
            text: result,
          },
        ]);
      },
    });
  }
  // ai - website quiz
  if (options.template === 'quiz') {
    // ai - generate outlines
    editor.addAction({
      id: 'action-ai-draft-quiz-answers',
      label: '🌟 Draft Quiz Answers ',
      precondition: null,
      keybindingContext: null,
      contextMenuGroupId: 'ai-quiz',
      contextMenuOrder: 1.3,
      run: async function (ed) {
        const currentSelection = editor.getSelection(),
          currentContent = editor.getModel().getValueInRange(currentSelection);
        //
        const message = getDraftQuizAnswerPrompt(currentContent);
        //
        const result = await editorAI
          .sendMessage(message)
          .then((result) => {
            return result.response.text();
          })
          .catch((ex) => {
            console.error(ex);
          });
        //
        editor.executeEdits('replace-selection', [
          {
            range: currentSelection,
            text: result,
          },
        ]);
      },
    });
  }
}
