import React, { useCallback, useEffect, useMemo, useState } from 'react';
// constants
// components
import { Page, PageNavigator } from 'Component/Page';
import Card from 'Component/Card';
// utils
import { call } from 'Util/Agent';

export default function (props) {
  const [members, setMembers] = useState([]);

  // lifecycle events
  // - listen to posts changes
  useEffect(() => {
    // get members from agent api
    call('/api/members').then(({ members }) => {
      members.sort((a, b) =>
        new Date(a.metadata.creationTime).getTime() >
        new Date(b.metadata.creationTime).getTime()
          ? -1
          : 1,
      );
      setMembers(members);
    });
  }, []);
  // - update document title
  useEffect(() => {
    document.title = `Members - TinyFrontend`;
  }, []);

  return (
    <Page>
      <PageNavigator />
      <div className="rounded-lg flex-1 overflow-y-auto">
        <div className="h-16 pt-2 text-4xl">{members.length} Members</div>
        <Card>
          <div className="sticky top-0 bg-white flex items-center p-4 text-sm text-center text-gray-800">
            <div className="w-12"></div>
            <div className="ml-4 flex-1">Email</div>
            <div className="ml-4 w-24 px-2">Created</div>
            <div className="ml-4 w-24 px-2">Last Seen</div>
          </div>
          {members.map((member) => {
            return (
              <div
                key={member.uid}
                className="mt-4 flex items-center p-4 rounded bg-gray-100 hover:bg-gray-200"
                onClick={() => {}}
              >
                <div
                  className={`rounded-full p-3 bg-white bg-cover w-12 h-12 inline-block text-xl`}
                  style={{
                    backgroundImage: `url('https://www.gravatar.com/avatar/${member.emal}?size=150')`,
                  }}
                ></div>
                <div className="ml-4 flex-1 text-lg">{member.email}</div>
                <span className="ml-4 inline-block w-24 px-2 py-2 bg-white rounded-full text-sm text-center text-gray-800">
                  {`${new Date(
                    member.metadata.creationTime,
                  ).toLocaleDateString()}`}
                </span>
                <span className="ml-4 inline-block w-24 px-2 py-2 bg-white rounded-full text-sm text-center text-gray-800">
                  {member.metadata.lastSignInTime
                    ? `${new Date(
                        member.metadata.lastSignInTime,
                      ).toLocaleDateString()}`
                    : '-'}
                </span>
              </div>
            );
          })}
        </Card>
      </div>
    </Page>
  );
}
