import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
// components
import LoginPage from 'Page/Login';
import PostsPage from './Page/Posts';
// utils
import firebase from './Util/Firebase';
import { getContentSlides } from './Util/Post';
// styles
import './styles.scss';
import './tailwind.css';
import './feather.scss';

const PAGES = {
  '/setup/:port': require('Page/Setup').default,
  '/post-player/:postId': require('Page/PostPlayer').default,
  '/post-viewer/:postId': require('Page/PostViewer').default,
  '/posts/:template': require('Page/Posts').default,
  '/post/:postId': require('Page/Post').default,
  '/members': require('Page/Members').default,
  '/excalidraw/:excalidrawId': require('Page/Excalidraw').default,
  '/batchai/:taskId': require('Page/BatchAI').default,
  '/terminal': require('Page/Terminal').default,
};

function FeaturePage() {
  // find current page + props
  const { Page, props } = useMemo(() => {
    for (let pattern in PAGES) {
      const [path, ...params] = pattern.split('/:'),
        url = location.search.substring(1);
      //
      if (url.startsWith(path)) {
        const props = {};
        const [_, ...values] = url.replace(path, '').split('/');

        params.map((param, index) => {
          props[param] = values[index];
        });
        // return the target page
        return {
          Page: PAGES[pattern],
          props: props,
        };
      }
    }
    // return default page
    return {
      Page: PostsPage,
      props: {
        template: 'website',
      },
    };
  }, []);

  // render UI
  return <Page {...props} />;
}

class App extends React.PureComponent {
  state = {
    status: 'INITIALIZING',
  };

  render() {
    // check page status
    switch (this.state.status) {
      case 'INITIALIZING':
        return null;
      case 'LOGIN':
        return <LoginPage />;
      default:
        // render pages based on url path
        return <FeaturePage />;
    }
  }

  componentDidMount(): void {
    // listen to login status
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        status: user ? 'DONE' : 'LOGIN',
      });
    });
  }
}
// render
const root = createRoot(document.getElementById('root'));
root.render(<App />);

// enable the dev hot-reload
if (module.hot) {
  module.hot.accept();
}
