export function parseTag(html) {
  const attribute = {};
  const matches = html.matchAll(/([a-z]+)="([^"]*)"/g);
  for (let [_, key, value] of matches) {
    if (!attribute[key]) {
      attribute[key] = value;
    } else if (Array.isArray(attribute[key])) {
      attribute[key].push(value);
    } else {
      attribute[key] = [attribute[key], value];
    }
  }
  return {
    tag: html.match(/<(\S+)/, 'g')[1],
    attribute,
  };
}
