import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// styles
import './styles.scss';

function BrowserModal() {
  const [url, setURL] = useState('');

  useEffect(() => {
    BrowserModal.show = setURL;
  }, []);

  return url
    ? ReactDOM.createPortal(
        <div className="browser-modal">
          <div className="browser-modal-container bg-white rounded-lg overflow-hidden">
            <iframe className="w-full h-full" src={url}></iframe>
          </div>
        </div>,
        document.body
      )
    : null;
}

export function openBrowserModal(url) {
  BrowserModal.show?.(url);
}

window.closeBrowserModal = () => {
  openBrowserModal('');
};

export default BrowserModal;
