import React, { useRef, useEffect, useMemo } from 'react';
// constants
import { TEMPLATES } from 'Template';
import { DOCUMENT_STATUS } from 'Constant/Document';
// utils
import { getContentSlides } from 'Util/Post';
import MarkdownDisplay from 'Component/MarkdownDisplay';
// styles
import './styles.scss';

export default function ({ post, onClick }) {
  const currentTemplate = useMemo(() => {
      return TEMPLATES.find((template) => template.id === post.template);
    }, [post.template]),
    currentStatus = useMemo(() => {
      return DOCUMENT_STATUS.find((status) => status.id === post.status);
    }, [post.status]);

  const title = useMemo(() => {
    return (
      post.content
        .trim()
        .match(/^#\s([^\n]*)/m)?.[1]
        .replace(/\*/g, '') ?? 'No Title'
    );
  }, [post.content]);

  return currentTemplate ? (
    <div
      className="mt-4 flex items-center p-4 rounded bg-gray-100 hover:bg-gray-200"
      onClick={onClick}
    >
      <div
        className={`rounded-full p-3 bg-white inline-block icon icon-${currentTemplate.icon} text-xl ${currentTemplate.color}`}
      ></div>
      <div className="ml-4 flex-1 text-lg">
        <MarkdownDisplay>{title}</MarkdownDisplay>
      </div>
      <span className="ml-4 inline-block w-16 px-2 py-2 bg-white rounded-full text-sm text-center text-gray-800">
        {post.slug ? `/${post.slug}` : '-'}
      </span>
      <span className="ml-4 inline-block w-20 px-2 py-2 bg-white rounded-full text-sm text-center text-gray-800">
        {currentStatus ? `${currentStatus.title}` : '-'}
      </span>
      <span className="ml-4 inline-block w-24 px-2 py-2 bg-white rounded-full text-sm text-center text-gray-800">
        {post.updateTime
          ? `${new Date(post.updateTime).toLocaleDateString()}`
          : '-'}
      </span>
    </div>
  ) : null;
}
