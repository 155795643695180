export const DOCUMENT_STATUS = [
  {
    id: 'todo',
    icon: 'clock',
    title: 'Todo',
  },
  {
    id: 'draft',
    icon: 'feather',
    title: 'Draft',
  },
  {
    id: 'review',
    icon: 'eye',
    title: 'Review',
  },
  {
    id: 'candidate',
    icon: 'flag',
    title: 'Candidate',
  },
  {
    id: 'published',
    icon: 'globe',
    title: 'Published',
  },
];
