export const PROMOPTS = {
  START_CHAT: () => {
    return {
      history: [
        {
          role: 'user',
          parts: [
            {
              text: `
                TinyFrontend is a blog website sharing all kinds of web frontend development related knowledge, its name is TinyFrontend and domain is https://tinyfrontend.com.
        
                You're a professional frontend developer and technical writer. You need to support me finish a blog post writing.
                Please only return the content in markdown, and do not add any extra words except the markdown content. I will use my own script to process the following steps.
                For heading content, please use corresponding heading tag.
                If there're any code snippet metioned in your writing, please try to use TinyFrontend or tinyfrontend.com as its demo.
            `,
            },
          ],
        },
        {
          role: 'model',
          parts: [
            {
              text: 'Great to meet you. I will support you with the post wiring.',
            },
          ],
        },
      ],
    };
  },
  INITIAL_MESSAGE: ({ content }) => {
    return `
        This is the current content I'm writing, not finished yet but please use it as a reference for the following queries.
        Please return OK if you get it.

        ${content}
    `;
  },
  GENERATE_OUTLINES: `
        I'm writing a blog post about {CONTENT}.
        Please help me to generate the outlines of this post.
        Please output in the folliwng markdown format:
        1. ....
        2. ....
        3. ....
        `,
  GENERATE_FIRST_SECTION: `
        Here are the outlines:
        {CONTENT}
        
        Please help me to write the first point of the outline items`,
  GENERATE_NEXT_SECTION: `
        Please continue with next outline point.
        
        If you have wrote all of the outline points, please output as "DONE"
      `,
};
