import React from 'react';
import MarkdownDisplay from 'Component/MarkdownDisplay';
// images
import CoverImage from './Asset/Image/Cover.png';
// styles
import './styles.scss';

export default function TemplateDefaultFirst({ index, count, children }) {
  return (
    <div
      className="template-default-first "
      style={{
        backgroundImage: `url(${CoverImage})`,
      }}
      data-screenshot={`${index}.png`}
    >
      <div className="template-default-first-content">
        <MarkdownDisplay>{children}</MarkdownDisplay>
      </div>
    </div>
  );
}
