import React from 'react';
// constants
import { TEMPLATES } from 'Template';
// components
import Card from '../Card';

const NAVIGATOR_LINKS = [
  ...TEMPLATES.map(({ id, icon, title }) => {
    return {
      url: `/?/posts/${id}`,
      icon,
      title,
    };
  }),
  '-',
  {
    url: `/?/batchai`,
    icon: 'coffee',
    title: 'Batch AI',
  },
  '-',
  {
    url: `/?/members`,
    icon: 'users',
    title: 'Members',
  },
];

function PageNavigatorLink({ isActive, icon, title, onClick }) {
  return (
    <div
      className={`mt-2 flex items-center rounded px-4 py-3 ${
        isActive ? 'bg-gray-100' : ''
      } hover:bg-gray-100`}
      onClick={onClick}
    >
      <span className={`icon icon-${icon} text-lg`}></span>
      <span className="mx-3 flex-1">{title}</span>
    </div>
  );
}

export function PageNavigator() {
  return (
    <div className="mr-4 w-52 overflow-y-auto catalog-sidebar">
      <div className="h-16 pt-2">
        <img
          className="w-20 catalog-logo"
          src="https://firebasestorage.googleapis.com/v0/b/tinyfrontend.appspot.com/o/tiny-post%2Fwebsite%2Flogo.png?alt=media&token=6aaab468-f3de-4394-ba92-39bb2b65e4bf"
          alt=""
        />
      </div>
      <Card>
        {NAVIGATOR_LINKS.map((link, index) => {
          // render divider
          if (link === '-') {
            return <hr key={index} className="my-4" />;
          }
          // render link
          const { title, icon, url } = link;
          return (
            <PageNavigatorLink
              key={url}
              isActive={location.href.includes(url)}
              icon={icon}
              title={title}
              onClick={() => {
                location.href = url;
              }}
            />
          );
        })}
      </Card>
    </div>
  );
}
