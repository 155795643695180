import React, { useRef, useEffect, useMemo } from 'react';
// components
import TemplateReelSlide from './slide';
// utils
import { openBrowserModal } from 'Component/BrowserModal';
import { getContentSectionsBySlide } from 'Util/Post';
import { call, isAvailable } from 'Util/Agent';
//
import './styles.scss';

function TemplateReel({ post, renderIndex, onReady }) {
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      onReady && onReady();
    }, 500);
  }, [post.id]);

  useEffect(() => {
    const { current } = ref;
    // set reel colors
    if (current) {
      current.style.setProperty('--theme-color', post.themeColor);
      current.style.setProperty('--primary-color', post.primaryColor);
    }
    //
  }, [post.themeColor]);

  const slides = useMemo(() => {
    return getContentSectionsBySlide(post.content);
  }, [post.content]);

  const renderIndexes = useMemo(() => {
    return renderIndex !== undefined
      ? [renderIndex]
      : slides.flat().map((item, index) => index);
  }, [renderIndex, slides]);

  return (
    <div ref={ref} className="template-reel-stage">
      {renderIndexes.map((index) => {
        return (
          <TemplateReelSlide key={index} slides={slides} renderIndex={index} />
        );
      })}
    </div>
  );
}

export default {
  id: 'reel',
  title: 'Reel',
  icon: 'instagram',
  color: 'text-orange-700',
  renderBuildButton: ({ post }) => {
    return (
      <button
        className="w-full py-3 px-4 rounded bg-orange-500 text-white hover:bg-orange-600"
        onClick={() => {
          // submit build job
          call(`/build/reel/${post.id}`);
          // show terminal
          openBrowserModal('/?/terminal');
        }}
      >
        <span className="mr-2 icon icon-archive"></span>Build Document
      </button>
    );
  },
  renderToolbar: ({ post, onChange, renderTextButton, renderColorButton }) => {
    return (
      <>
        {renderColorButton({
          key: 'themeColor',
          icon: 'droplet',
          className: 'w-32',
        })}
      </>
    );
  },
  renderTemplate: (props) => {
    return <TemplateReel {...props} />;
  },
};
