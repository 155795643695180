import firebase from 'Util/Firebase';
// constants
import { PROMOPTS } from './constants';

type Props = {
  content: string;
};

export default class EditorAI {
  ai = null;
  props: Props = {
    content: '',
  };

  constructor(props: Props = {}) {
    this.props = props;
  }

  connect = async () => {
    // already connect?
    if (this.ai) {
      return;
    }
    // start new chat
    this.ai = firebase.ai.startChat(PROMOPTS.START_CHAT());
    // send initial message
    if (this.props.content) {
      await this.ai.sendMessage(
        PROMOPTS.INITIAL_MESSAGE({
          content: this.props.content,
        }),
      );
    }
  };

  sendMessage = async (message) => {
    await this.connect();
    return this.ai.sendMessage(message);
  };
}
