import React, { useState, useEffect, useCallback } from 'react';
// constants
import Card from 'Component/Card';
// utils
import firebase from 'Util/Firebase';
import { call } from 'Util/Agent';
import CodeEditor from 'Component/CodeEditor';
import LoadTimer from 'Component/LoadTimer';
import MarkdownDisplay from 'Component/MarkdownDisplay';
import { getQuestions, getQuestionPrompt } from './utils';
import EditorAI from 'Component/CodeEditor/EditorAI';

export default function () {
  const [step, setStep] = useState(1);
  const [status, setStatus] = useState('');
  const [content, setContent] = useState(
    `[
  {
    "title": "{QUESTION_DESCRIPTION}",
    "companies": ["{COMPANY_A}", "{COMPANY_B}", ...],
    "tags": ["{TAG_A}", "{TAG_B}", ...],
    "category": "{CATEGORY}",
    "frequency": 0-100,
    "difficulty": "easy" | "medium" | "hard"
  }
]`,
  );
  const [tasks, setTasks] = useState([]);

  // handlers
  const handleImportButtonClick = useCallback(async () => {
    const questions = getQuestions(content);
    console.log('>> ques', questions);
    if (status) {
      return;
    }

    //
    const editorAI = new EditorAI();
    setStatus(`[0/${questions.length}]`);
    //
    for (let i = 0, l = questions.length; i < l; i++) {
      const question = questions[i];
      setStatus(`[${i + 1}/${l}] ${question.title}`);
      //
      const response = await editorAI
        .sendMessage(getQuestionPrompt(question.title))
        .then((result) => {
          return result.response.text();
        });
      //
      await firebase.firestore().collection('tiny-post').add({
        template: 'quiz',
        status: 'todo',
        slug: '1999',
        content: response,
        primaryColor: '#FFA500',
        themeColor: '#828282',
        createTime: Date.now(),
        updateTime: Date.now(),
      });
      //
    }
    //
    alert('DONE!');
    //
  }, [content, status]);

  return (
    <>
      <Card className="h-2/3">
        <CodeEditor value={content} onChange={setContent} />
      </Card>
      <Card className="flex justify-between items-center">
        <span>{status}</span>
        <button
          className="py-3 px-4 rounded bg-blue-500 text-white hover:bg-blue-600"
          onClick={handleImportButtonClick}
        >
          <span className="mr-2 icon icon-upload-cloud"></span>
          Import Quizes
        </button>
      </Card>
    </>
  );
}
