import firebase from './Firebase';

const servers = ['http://localhost:6628', 'https://agent.tinyfrontend.com'];

const agent = {
  available: false,
  url: '',
};

export function call(api, options = {}) {
  const url = api.startsWith('http')
    ? api
    : `${agent.url || 'https://agent.tinyfrontend.com'}${api}`;
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      token: 'e0fa19e6fd10dba2e36072aad6730500',
    },
  }).then((res) => res.json());
}

// check available servers
function checkServers() {
  Promise.allSettled(servers.map((server) => call(`${server}/ping`))).then(
    (results) => {
      const available = results
        .map(({ status }, index) => {
          return {
            status,
            server: servers[index],
          };
        })
        .find((server) => server.status === 'fulfilled');

      if (available) {
        agent.available = true;
        agent.url = available.server;
      } else {
        agent.available = false;
        agent.url = '';
      }
    },
  );
}

setInterval(checkServers, 60000);
checkServers();

export function isAvailable() {
  return agent.available;
}

export function getAgent() {
  return agent.url;
}
