import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import CodeDisplay from 'Component/CodeDisplay';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// utils
import md5 from 'md5';
import { parseTag } from './utils';
// styles
import './styles.scss';

export default function MarkdownDisplay({ children, codeTheme }) {
  const markdown = useMemo(() => {
    return children.replace(/<[A-Z][^>]*>/gm, ($0) => {
      const { tag, attribute } = parseTag($0);
      switch (tag) {
        case 'Excalidraw':
          const excalidrawId = attribute.url.split('/').pop();
          return `![${
            attribute.title ?? ''
          }](https://tinyfrontend.com/image/${excalidrawId}?_t=${Math.round(
            Date.now() / 60000,
          )})`;
        default:
          return $0;
      }
    });
  }, [children]);
  const renderCODE = ({ node, children, className, inline, ...props }) => {
    if (inline) {
      // render inline code block
      return <span>{children}</span>;
    } else {
      // render multiline code block
      const language = className?.replace('language-', '');
      return (
        <SyntaxHighlighter
          {...props}
          className="markdown-display-code"
          children={String(children).replace(/\n$/, '')}
          style={codeTheme}
          language={language}
        />
      );
    }
  };

  return (
    <Markdown
      components={{
        code: renderCODE,
      }}
    >
      {markdown}
    </Markdown>
  );
}
