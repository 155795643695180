// utils
import firebase from './Firebase';
import md5 from 'md5';

export function openAndUploadFile({ storage }: { storage: string }) {
  return new Promise((resolve) => {
    // create file input
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .webp, .gif';
    input.style.display = 'none';
    document.body.appendChild(input);
    // listen to file selected
    input.addEventListener('change', (e) => {
      const reader = new FileReader();
      //
      reader.onload = async function (e) {
        // remove input
        document.body.removeChild(input);
        // upload file
        const ref = firebase
          .storage()
          .ref(`${storage}/${md5(e.target?.result)}.webp`);
        await ref.putString(e.target.result, 'data_url');
        // get download url
        const url = await ref.getDownloadURL();
        // return file url
        resolve(url);
      };

      reader.readAsDataURL(input.files[0]);
    });
    // open file picker
    input.click();
  });
}

export async function getDownloadURL(path) {
  return await firebase.storage().ref(path).getDownloadURL();
}

export async function upload({ path, blob }) {
  // get storage ref
  const ref = firebase.storage().ref(path);
  // upload blob data
  await ref.put(blob);
  // return download url
  return await ref.getDownloadURL();
}

export function download({ url }) {
  const a = document.createElement('a');
  a.download = url.split('/').pop();
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function getStoragePath({
  type,
  filename,
}: {
  type: 'image' | 'excalidraw';
  filename: string;
}) {
  const folder = type === 'image' ? 'images' : type;
  return `tiny-post/${folder}/${filename}`;
}
