import React from 'react';

export function Page({ className = '', children }) {
  return (
    <div
      className={`flex items-stretch h-full catalog cursor-default ${className}`}
    >
      {children}
    </div>
  );
}
