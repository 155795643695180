export function getDraftQuizAnswerPrompt(question) {
  return `You're an web frontend expert, please provide me the answer to the following interview question and fulfill the task demands:

Question:

${question}

Tasks:
1. help me to rewrite the question description.
2. provide the answer in 2 main sections: "Quick Answer" and "Explain in Depth".
3. for the "Quick Answer" section, please try provide a briefing answer in clean words.
4. for the "Explain in Depth" section, please include more details, better with demo code snippets.
5. for all the above output, please fill in this MarkDown format:

# Quiz

{REWRITED_QUESTION_DESCRIPTION}

## Quick Answer

{QUICK_ANSWER_CONTENT}

## Explain in Depth

{EXPLAIN_IN_DEPTH_CONTENT}
`;
}
