export function getRenderSectionsAndIndex(slides, renderIndex) {
  const sectionsInPage = [];
  //
  slides.map((slide, page) => {
    slide.map((section, index) => {
      sectionsInPage.push({
        page,
        index,
      });
    });
  });
  //
  const { page, index } = sectionsInPage[renderIndex];

  return {
    sections: slides[page],
    validIndex: index,
  };
}
