export const PREDEFINED_COLORS = [
  { title: 'None', color: '#FFFFFF00' },
  { title: 'White', color: '#FFFFFF' },
  { title: 'Carousel', color: '#343434' },
  { title: 'Code', color: '#272822' },
];

export const PREDEFINED_GRID_RATIOS = [
  { title: 'None', value: '' },
  { title: 'Thumbnail', value: '16/9' },
];
