import React, { useCallback, useEffect, useMemo, useState } from 'react';
// constants
import { TEMPLATES } from 'Template';
import { DOCUMENT_STATUS } from 'Constant/Document';
// components
import { Page, PageNavigator } from 'Component/Page';
import Card from 'Component/Card';
import Post from './Component/Post';
// utils
import firebase from 'Util/Firebase';
// styles
import './styles.scss';

export default function (props) {
  // states
  const [posts, setPosts] = useState([]);
  const [filterByKeyword, setFilterByKeyword] = useState('');
  const [filterByStatus, setFilterByStatus] = useState('');

  const displayPosts = useMemo(() => {
    return posts
      .filter((post) => !filterByStatus || post.status === filterByStatus)
      .filter((post) => {
        // not matched by default
        let isMatched = false;
        // do empty serach
        if (!filterByKeyword) {
          isMatched = true;
        }
        // do slug search
        if (!isMatched && filterByKeyword.startsWith('#') && post.slug) {
          isMatched = isMatched || `#${post.slug}`.startsWith(filterByKeyword);
        }
        // do text search
        if (!isMatched && filterByKeyword) {
          isMatched =
            isMatched ||
            post.content
              .toLowerCase()
              .indexOf(filterByKeyword.toLocaleLowerCase()) !== -1;
        }
        // return result
        return isMatched;
      });
  }, [posts, filterByKeyword, filterByStatus]);

  const currentTemplate = useMemo(() => {
    return TEMPLATES.find((template) => template.id === props.template);
  }, []);

  const currentCounts = useMemo(() => {
    const res = {};
    // calculate counts
    posts.map((post) => {
      res[post.status] = res[post.status] ? res[post.status] + 1 : 1;
    });
    return res;
  }, [posts]);

  // lifecycle events
  // - listen to posts changes
  useEffect(() => {
    //
    const unsubscribe = firebase
      .firestore()
      .collection('tiny-post')
      .where('template', '==', props.template)
      .orderBy('updateTime', 'desc')
      .onSnapshot((res) => {
        const posts = firebase.utils.parsePosts(res);
        setPosts(posts);
      });
    // unsubscribe when unmount
    return () => {
      unsubscribe();
    };
  }, []);

  // update document title
  useEffect(() => {
    document.title = `${currentTemplate.title}s - TinyFrontend`;
  }, [currentTemplate]);

  // event handlers
  const handlePostCreate = async () => {
    // create new post
    const { id } = await firebase.firestore().collection('tiny-post').add({
      template: props.template,
      status: 'draft',
      content: '# NEW: New Topic',
      primaryColor: '#FFA500',
      themeColor: '#828282',
      createTime: Date.now(),
      updateTime: Date.now(),
    });
    // set the new post as active
    handlePostClick({ id });
  };

  const handlePostClick = useCallback((post) => {
    window.open(`/?/post/${post.id}`);
  }, []);

  return (
    <Page className="catalog">
      <PageNavigator />
      <div className="rounded-lg flex-1 overflow-y-auto">
        {/* title */}
        <div className="h-16 pt-2 text-4xl">
          {displayPosts.length} {currentTemplate.title}s
        </div>

        <Card>
          {/* headers */}
          <div className="sticky top-0 bg-white flex items-center p-4 text-sm text-center text-gray-800">
            <div className="w-12">Type</div>
            <div className="ml-4 flex-1">Title</div>
            <div className="ml-4 w-16 px-2">Slug</div>
            <div className="ml-4 w-20 px-2">Status</div>
            <div className="ml-4 w-24 px-2">Modified</div>
          </div>
          {/* posts */}
          {displayPosts.map((post) => {
            return (
              <Post
                key={post.id}
                post={post}
                onClick={() => {
                  handlePostClick(post);
                }}
              />
            );
          })}
        </Card>
      </div>
      <div className="ml-4 w-64">
        <div className="h-16 pt-2 text-4xl"></div>
        <Card>
          <button
            className="w-full py-3 px-4 rounded bg-blue-500 text-white hover:bg-blue-600"
            onClick={handlePostCreate}
          >
            <span className="mr-2 icon icon-file-plus"></span>New{' '}
            {currentTemplate.title}
          </button>
        </Card>
        <Card>
          <div className="text-sm text-gray-500">Search</div>
          <input
            type="text"
            className="mt-2 block rounded border-2 border-gray-100 w-full px-4 py-2"
            placeholder="🔍 #0102 / JavaScript"
            value={filterByKeyword}
            onChange={(e) => {
              setFilterByKeyword(e.target.value);
            }}
          />
        </Card>
        <Card>
          {DOCUMENT_STATUS.map(({ id, icon, title }) => {
            return (
              <div
                key={id}
                className={`mt-2 flex items-center rounded px-4 py-3 ${
                  id === filterByStatus ? 'bg-gray-200' : ''
                } hover:bg-gray-100`}
                onClick={() => {
                  const value = filterByStatus === id ? '' : id;
                  setFilterByStatus(value);
                }}
              >
                <span className={`icon icon-${icon} text-lg`}></span>
                <span className="mx-3 flex-1">{title}</span>
                <span className="rounded bg-gray-200 px-2 text-sm">
                  {currentCounts[id]}
                </span>
              </div>
            );
          })}
        </Card>
      </div>
    </Page>
  );
}
