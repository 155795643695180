import React, { useState, useEffect, useMemo } from 'react';
// styles
import './styles.scss';
// constants
import { TEMPLATES } from 'Template';
import { DOCUMENT_STATUS } from 'Constant/Document';

export default function EditorTabs({ post, onChange }) {
  const [changes, setChanges] = useState(post);

  useEffect(() => {
    onChange(changes);
  }, [changes]);

  // renders
  const renderImageButton = ({ key, url, onClick }) => {
    return (
      <div
        key={key}
        className="mt-2 flex border border-gray-200 rounded"
        onClick={onClick}
      >
        <img src={url} />
      </div>
    );
  };
  const renderTextButton = ({ key, icon, text, onClick }) => {
    return (
      <div
        key={key}
        className="mt-2 flex border border-gray-200 rounded w-full hover:bg-gray-100"
        onClick={onClick}
      >
        {icon ? (
          <span
            className={`bg-gray-100 p-2 px-4 flex items-center justify-center icon icon-${icon}`}
          ></span>
        ) : null}
        {text ? <button className="py-2 px-4 ">{text}</button> : null}
      </div>
    );
  };

  const renderColorButton = ({ key, icon, className }) => {
    return (
      <div
        key={key}
        className={`mt-2 flex border border-gray-200 rounded rounded w-full`}
      >
        <span
          className={`bg-gray-100 p-2 px-4 flex items-center justify-center icon icon-${icon}`}
        ></span>
        <input
          type="color"
          className="mx-2 block flex-1 h-10 py-1 self-center bg-transparent"
          value={changes[key]}
          onChange={(e) => {
            setChanges({
              ...changes,
              [key]: e.target.value,
            });
          }}
        />
      </div>
    );
  };

  const renderTextInput = ({ key, icon, placeholder, className }) => {
    return (
      <div
        key={key}
        className={`mt-2 flex rounded border border-gray-200 w-full`}
      >
        <span
          className={`bg-gray-100 p-2 px-4 flex items-center justify-center icon icon-${icon}`}
        ></span>
        <input
          type="text"
          className="block flex-1 p-2"
          style={{ width: '1%' }}
          placeholder={placeholder}
          value={changes[key]}
          onChange={(e) => {
            setChanges({
              ...changes,
              [key]: e.target.value,
            });
          }}
        />
      </div>
    );
  };

  const renderSelectButton = ({ key, icon, options = [], className }) => {
    return (
      <div
        key={key}
        className={`mt-2 flex rounded border border-gray-200 w-full`}
      >
        <span
          className={`bg-gray-100 p-2 px-4 flex items-center justify-center icon icon-${icon}`}
        ></span>
        <select
          className="block flex-1 p-2"
          value={changes[key]}
          onChange={(e) => {
            setChanges({
              ...changes,
              [key]: e.target.value,
            });
          }}
        >
          {options.map(({ id, title }) => {
            return (
              <option key={id} value={id}>
                {title}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const renderDivider = () => {
    return <hr className="my-4" />;
  };

  const currentTemplate = useMemo(() => {
    return TEMPLATES.find((template) => template.id === post.template);
  }, [post.template]);

  const currentStatus = useMemo(() => {
    return (
      DOCUMENT_STATUS.find((status) => status.id === post.status) ||
      DOCUMENT_STATUS[0]
    );
  }, [post.status]);

  return (
    <div>
      {renderSelectButton({
        key: 'status',
        icon: currentStatus.icon,
        options: DOCUMENT_STATUS,
      })}
      <hr className="my-4" />
      {currentTemplate.renderToolbar({
        post,
        onChange,
        renderTextButton,
        renderColorButton,
        renderTextInput,
        renderDivider,
        renderSelectButton,
        renderImageButton,
      })}
    </div>
  );
}
