import React from 'react';
// Template
import Template from '../../Template';
// utils
import firebase from '../../Util/Firebase';
import { getContentSlides, getContentSectionsBySlide } from '../../Util/Post';
//styles
import './styles.scss';

export default class PostPlayer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      post: null,
      isReady: false,
      renderIndex: undefined,
    };
  }

  fetchPost = async () => {
    // fetch post from firestore
    const post = await firebase
      .firestore()
      .collection('tiny-post')
      .doc(this.props.postId)
      .get()
      .then(firebase.utils.parsePost);
    //
    this.setState({
      post,
    });
  };

  play = () => {
    const { renderIndex } = this.state;

    this.setState({
      renderIndex: renderIndex === undefined ? 0 : renderIndex + 1,
    });
  };

  handleTemplateReady = () => {
    this.setState({
      isReady: true,
    });
  };

  renderPost() {
    const { post, isReady, renderIndex } = this.state;
    return (
      <div data-status={isReady ? 'playing' : 'prepare'}>
        <Template
          key={post.id}
          post={post}
          renderIndex={renderIndex}
          onReady={this.handleTemplateReady}
        />
        {/* shadow render */}
        {/* <div className="post-player-shadow">
          <Template key={post.id} post={post} />
        </div> */}
      </div>
    );
  }

  render() {
    const { post } = this.state;
    return <div className="post-player">{post ? this.renderPost() : null}</div>;
  }

  componentDidMount(): void {
    this.fetchPost();
    window.play = this.play;
    // change style
    document.body.style.overflow = 'auto';
    document.querySelector('#root').style.padding = 0;
  }
}
