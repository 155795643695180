export function getContentSlides(content, { mode } = {}) {
  const slides = content.split(/\n-{3,}/);
  // for cover
  // - only return 1st slide
  if (mode === 'cover') {
    return slides.slice(0, 1);
  }
  // for preview
  // - return by ---
  if (mode === 'preview') {
    return slides;
  }
  // for production
  return slides
    .map((slide, index) => {
      const results = [];
      let lines = [],
        speaks = [],
        isInSpeaking = false;
      //
      slide.split('\n').map((line, index) => {
        //
        const isSpeak = !!line.match(/^>\W/),
          isEmpty = !line.trim();

        // step into speaking area
        if (!isEmpty && isSpeak && !isInSpeaking) {
          isInSpeaking = true;
        }
        // step out speaking area
        if (!isEmpty && !isSpeak && isInSpeaking) {
          isInSpeaking = false;
          speaks = [];
          results.push(lines.concat(speaks).join('\n'));
        }
        // add current line to contents
        if (isSpeak) {
          speaks.push(line);
        } else {
          lines.push(line);
        }
      });
      // append rest
      results.push(lines.join('\n'));
      //
      return results;
    })
    .flat();
}

export function getContentSectionsBySlide(content) {
  const sections = [];

  const slides = content.split(/\n-{3,}/);

  return slides.map((slide) => {
    const sections = [];
    let currentSection = [],
      isInSpeaking = false;
    //
    slide.split('\n').map((line, index) => {
      //
      const isSpeak = !!line.match(/^>\W/),
        isEmpty = !line.trim();

      // step into speaking area
      if (!isEmpty && isSpeak && !isInSpeaking) {
        isInSpeaking = true;
      }
      // step out speaking area
      if (!isEmpty && !isSpeak && isInSpeaking) {
        isInSpeaking = false;
        sections.push(currentSection);
        currentSection = [];
      }
      // add current line to contents
      if (!isSpeak) {
        currentSection.push(line);
      }
    });
    // append the result lines
    sections.push(currentSection);
    // return
    return sections.map((section) => {
      return section.join('\n').replace(/\n{3,}/g, '\n\n');
    });
  });
}
