import React from 'react';
import ReactDOM from 'react-dom';
// utils
import firebase from 'Util/Firebase';
import { call, getAgent } from 'Util/Agent';

export default class Terminal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
    };
  }

  handleCloseClick = () => {
    window.top.closeBrowserModal();
  };

  render() {
    return (
      <div className="flex flex-col rounded-lg bg-white overflow-hidden w-full h-full p-4">
        <p className="flex items-center justify-between">
          <div>
            <span className="mr-2 icon icon-server"></span> {getAgent()}
          </div>
          <button
            className="mr-1 rounded-lg bg-gray-200 py-2 px-4 hover:bg-gray-100"
            onClick={this.handleCloseClick}
          >
            Close
          </button>
        </p>
        <div className="mt-4 flex-1 rounded border-2 background-gray-100 p-4">
          {this.state.logs.map((record, index) => {
            const { timestamp, content } = record,
              isLink = content.match(/^http/);
            return (
              <p key={index} className="">
                <span className="mr-2">
                  [{new Date(timestamp).toLocaleTimeString()}]
                </span>
                {isLink ? (
                  <a className="text-blue-600" href={content} target="_blank">
                    {content}
                  </a>
                ) : (
                  <span>{content}</span>
                )}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    this.fetchTimer = setInterval(() => {
      call(`/terminal/output`).then((res) => {
        this.setState({
          logs: res.logs.filter((record) => record.timestamp),
        });
      });
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.fetchTimer);
  }
}
