import React from 'react';
// utils
import { call } from 'Util/Agent';
import { getContentSlides } from 'Util/Post';
import { getPreviewURL } from './utils';
//
import './styles.scss';

function TemplateNewsletter({ post }) {
  return (
    <iframe
      className="border-2 border-gray-100 bg-white w-full h-full"
      width="100%"
      height="100%"
      src={getPreviewURL(post)}
    />
  );
}

export default {
  id: 'newsletter',
  title: 'Newsletter',
  icon: 'mail',
  color: 'text-green-600',
  onSave: async ({ post }) => {
    // run post deploy automatically
    await call(`/build/newsletter/${post.id}`);
  },
  renderBuildButton: ({ post }) => {
    return (
      <button
        className="w-full py-3 px-4 rounded bg-orange-500 text-white hover:bg-orange-600"
        onClick={() => {
          let previewEmail = '';
          // ask to input preview email
          if (post.status !== 'production') {
            previewEmail = prompt(
              'Please input your preview email address:',
              localStorage.getItem('newsletter.email') ?? '',
            );
            if (previewEmail) {
              localStorage.setItem('newsletter.email', previewEmail);
            }
          }
          if (!previewEmail) {
            return;
          }
          // submit newsletter send request
          call(`/api/newsletter/send/${post.id}?previewEmail=${previewEmail}`);
        }}
      >
        <span className="mr-2 icon icon-mail"></span>
        {post.status === 'published' ? 'Publish Newsletter' : 'Preview Email'}
      </button>
    );
  },
  renderToolbar: ({
    post,
    onChange,
    renderTextButton,
    renderColorButton,
    renderTextInput,
    renderDivider,
    renderImageButton,
  }) => {
    const previewURL = getPreviewURL(post);

    return (
      <>
        {renderColorButton({
          key: 'primaryColor',
          icon: 'type',
          className: 'w-32',
        })}
        {renderDivider()}
        {renderImageButton({
          url: `https://quickchart.io/qr?text=${encodeURIComponent(
            previewURL,
          )}&margin=2&size=200`,
          onClick: () => {
            window.open(previewURL);
          },
        })}
      </>
    );
  },
  renderTemplate: (props) => {
    return <TemplateNewsletter {...props} />;
  },
};
