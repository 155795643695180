import React from 'react';
import MarkdownDisplay from 'Component/MarkdownDisplay';
// images
import LogoImage from './Asset/Image/Logo.png';
// styles
import './styles.scss';

export default function TemplateFirst({ index, page, pageCount, children }) {
  return (
    <div className="template-default-middle" data-screenshot={`${index}.png`}>
      <div className="template-default-middle-body">
        <img
          src={LogoImage}
          alt=""
          className="template-default-middle-body-logo"
        />
        <div className="template-default-middle-body-content">
          <MarkdownDisplay>{children}</MarkdownDisplay>
        </div>
      </div>
      <div className="template-default-middle-header">
        <div className="template-default-middle-header-progress">
          <div
            className="template-default-middle-header-progress-scroller"
            style={{
              height: Math.round((page / pageCount) * 100) + '%',
            }}
          ></div>
        </div>
        <div className="template-default-middle-header-pager">0{page}</div>
      </div>
    </div>
  );
}
