import React, { useEffect } from 'react';

export default function SetupPage({ port }) {
  useEffect(() => {
    localStorage.setItem('agent.url', `http://localhost:${port}`);
    location.href = '/';
  }, []);

  return <div>Loading</div>;
}
