import React, { useEffect } from 'react';
// utils
import firebase from 'Util/Firebase';

export default function LoginPage() {
  // expose login methods
  useEffect(() => {
    window.login = function (password) {
      firebase
        .auth()
        .signInWithEmailAndPassword('admin@tinyfrontend.com', password);
    };
  }, []);

  useEffect(() => {
    try {
      const password = prompt('PASSWORD:');
      window.login(password);
    } catch (ex) {}
  }, []);

  return <div className="login-page"></div>;
}
