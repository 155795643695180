import React, { useCallback, useEffect, useMemo, useState } from 'react';
// constants
import { TASKS } from './constants';
// components
import { Page, PageNavigator } from 'Component/Page';
import Card from 'Component/Card';
import QuizImporter from './Task/QuizImporter';
// utils
import firebase from 'Util/Firebase';
// styles
import './styles.scss';

export default function (props) {
  const [currentTask, setCurrentTask] = useState(
    props.taskId ?? 'quiz-importer',
  );

  useEffect(() => {
    document.title = 'Batch AI - TinyFrontend';
  }, []);

  return (
    <Page className="catalog">
      <PageNavigator />
      <div className="rounded-lg flex-1 overflow-y-auto">
        {/* title */}
        <div className="h-16 pt-2 text-4xl">Batch AI</div>
        <QuizImporter />
      </div>
      <div className="ml-4 w-64">
        <div className="h-16 pt-2 text-4xl"></div>
        <Card>
          {TASKS.map(({ id, icon, title }) => {
            return (
              <div
                key={id}
                className={`mt-2 flex items-center rounded px-4 py-3 ${
                  id === currentTask ? 'bg-gray-200' : ''
                } hover:bg-gray-100`}
                onClick={() => {}}
              >
                <span className={`icon icon-${icon} text-lg`}></span>
                <span className="mx-3 flex-1">{title}</span>
              </div>
            );
          })}
        </Card>
      </div>
    </Page>
  );
}
