import React from 'react';
// Template
import Template from '../../Template';
// utils
import firebase from '../../Util/Firebase';
import { getContentSlides } from '../../Util/Post';
//styles
import './styles.scss';

export default class PostViewer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      post: null,
      activeIndex: 0,
    };
  }

  fetchPost = async () => {
    // fetch post from firestore
    const post = await firebase
      .firestore()
      .collection('tiny-post')
      .doc(this.props.postId)
      .get()
      .then(firebase.utils.parsePost);
    //
    this.setState({
      post,
    });
    //
    document.body.style.setProperty('--theme-color', post.themeColor);
    document.body.style.setProperty('--primary-color', post.primaryColor);
  };

  renderPost() {
    const { post, activeIndex } = this.state,
      slides = getContentSlides(post.content);

    return <Template post={post} />;
  }

  render() {
    const { post } = this.state;
    return (
      <div className="post-viewer" data-screenshots>
        {post ? this.renderPost() : null}
      </div>
    );
  }

  componentDidMount(): void {
    this.fetchPost();
    // change style
    document.body.style.overflow = 'auto';
  }
}
