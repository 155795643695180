import React, { useState, useCallback } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.main';
import html2canvas from 'html2canvas';
import { download } from 'Util/Storage';
// styles
import './styles.scss';

export default class CodeModal extends React.Component {
  static defaultProps = {
    onDone: () => {},
    onCancel: () => {}
  };

  editorRef = React.createRef();

  state = {
    isProcessing: false,
    language: this.props.language || 'javascript',
    codeSnippet: this.props.codeSnippet || '',
  };

  handleDoneClick = () => {
    this.setState(
      {
        isProcessing: true,
      },
      () => {
        html2canvas(
          document.querySelector('.excalidraw-code-modal-preview'),
        ).then((_canvas) => {
          const canvas = document.createElement('canvas');
          canvas.width = _canvas.width - 4 - 80;
          canvas.height = _canvas.height - 50 - 40;
          canvas
            .getContext('2d')!
            .drawImage(
              _canvas,
              40,
              80,
              canvas.width,
              canvas.height,
              0,
              0,
              canvas.width,
              canvas.height,
            );
          // remove the background: #272822
          // const image = canvas
          //   .getContext('2d')
          //   .getImageData(0, 0, canvas.width, canvas.height);
          // const targetColor = { r: 39, g: 40, b: 34 };

          // for (let i = 0; i < image.data.length; i += 4) {
          //   const r = image.data[i];
          //   const g = image.data[i + 1];
          //   const b = image.data[i + 2];

          //   if (
          //     r === targetColor.r &&
          //     g === targetColor.g &&
          //     b === targetColor.b
          //   ) {
          //     image.data[i + 3] = 0; // Set alpha to 0 (transparent)
          //   }
          // }
          // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
          // canvas.getContext('2d').putImageData(image, 0, 0);
          // open image in new browser window
          canvas.toBlob((blob) => {
            download({
              url: URL.createObjectURL(blob),
            });
          }, 'image/webp');
          // return
          this.props.onDone({
            language: this.state.language,
            codeSnippet: this.state.codeSnippet,
          });
        });
      },
    );
  };

  render() {
    return (
      <div className="absolute z-50 top-0 left-0 w-full h-full bg-white flex flex-col p-4">
        <div className="flex">
          <select
            type="text"
            className="block rounded-lg border border-gray-200 w-40 px-4"
            value={this.state.language}
            placeholder="Language"
            onChange={(e) => this.setState({ language: e.target.value })}
          >
            <option value="javascript">JavaScript</option>
            <option value="css">CSS</option>
            <option value="html">HTML</option>
            <option value="json">JSON</option>
            <option value="shell">Shell</option>
          </select>
          <div className="flex-1"></div>
          <button className="mr-4 rounded-lg bg-gray-200 py-2 px-4 hover:bg-gray-100" onClick={this.props.onCancel}>
            Cancel
          </button>
          <button
            className="rounded-lg py-2 px-4 bg-blue-500 text-white hover:bg-blue-600"
            onClick={this.handleDoneClick}
          >
            <span className="mr-2 icon icon-download"></span>
            Done
          </button>
        </div>
        <div
          ref={this.editorRef}
          className="mt-4 flex-1 rounded-lg bg-gray-100 w-full h-full p-4"
        ></div>
        {this.state.isProcessing ? (
          <div className="excalidraw-code-modal-preview absolute">
            <SyntaxHighlighter language={this.state.language} style={okaidia}>
              {this.state.codeSnippet}
            </SyntaxHighlighter>
          </div>
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    this.editor = monaco.editor.create(this.editorRef.current, {
      value: this.state.codeSnippet,
      fontSize: 16,
      fontFamily: 'monospace',
      minimap: {
        enabled: false,
      },
      padding: {
        top: 16,
        bottom: 16,
      },
      scrollBeyondLastLine: false,
      scrollbar: {
        verticalScrollbarSize: '6px',
        alwaysConsumeMouseWheel: false,
      },
    });
    // handle content changes
    this.editor.onDidChangeModelContent((e) => {
      this.setState({
        codeSnippet: this.editor.getValue(),
      });
    });
  }
}
