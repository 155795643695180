// templates
import First from './First';
import Middle from './Middle';
import Last from './Last';
// utils
import { getContentSlides } from 'Util/Post';
// styles
import './styles.scss';

export default function ({ mode, post, renderIndexes = [] }) {
  const slides = getContentSlides(post.content).concat(['']);

  return (
    <div
      className="template-default-slides"
      style={{
        zoom: mode === 'preview' ? 0.4 : 1,
      }}
    >
      {slides.map((slide, index) => {
        let template = null;
        // don't render?
        if (renderIndexes.length > 0 && !renderIndexes.includes(index)) {
          return null;
        }
        // render contents
        if (index === 0) {
          template = <First index={index}>{slide}</First>;
        } else if (index + 1 < slides.length) {
          template = (
            <Middle index={index} page={index} pageCount={slides.length - 1}>
              {slide}
            </Middle>
          );
        } else {
          template = <Last index={index} />;
        }
        //
        return template;
      })}
    </div>
  );
}
