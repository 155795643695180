import React, { useRef, useEffect, useMemo } from 'react';
// components
import MarkdownDisplay from 'Component/MarkdownDisplay';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
// utils
import { getContentSlides, getContentSectionsBySlide } from 'Util/Post';
import { getRenderSectionsAndIndex } from './utils';
//
import './styles.scss';

function TemplateCarousellSlide({ slug = '', tags = '', slides, renderIndex }) {
  const { sections, validIndex } = useMemo(() => {
    return getRenderSectionsAndIndex(slides, renderIndex);
  }, [slides, renderIndex]);

  return (
    <div
      className="template-slide template-carousell"
      data-index={renderIndex}
      data-screenshot={`${renderIndex}.png`}
    >
      <div className="template-carousell-container">
        <div className="template-carousell-header">{tags}</div>
        <div className="template-carousell-body">
          {sections.map((section, index) => {
            return (
              <div
                key={section}
                className="template-carousell-body-section"
                style={{ opacity: index > validIndex ? 0 : 1 }}
              >
                <MarkdownDisplay codeTheme={a11yDark}>
                  {section}
                </MarkdownDisplay>
              </div>
            );
          })}
        </div>
        <div className="template-carousell-footer">
          <div className="template-carousell-footer-progress">
            {slides.length > 1
              ? slides.map((slide, index) => {
                  return (
                    <span
                      className={`template-carousell-footer-progress-bar ${
                        renderIndex === index ? 'active' : ''
                      }`}
                    ></span>
                  );
                })
              : null}
          </div>
          <div className="template-carousell-footer-url">
            <span className="template-carousell-footer-url-domain">
              https://tinyfrontend.com/
            </span>
            {slug.split('').map((num, index) => {
              return (
                <span
                  key={index}
                  className="template-carousell-footer-url-number"
                >
                  {num}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TemplateCarousellSlide);
