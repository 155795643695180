import { getContentSlides } from 'Util/Post';

export function getActiveSlideByLineNumber({ content, activeLineNumber }) {
  const slides = getContentSlides(content);
  let lineCount = 0;
  for (let i = 0, l = slides.length; i < l; i++) {
    lineCount += slides[i].split('\n').length;
    if (activeLineNumber <= lineCount) {
      return i;
    }
  }
  return slides.length - 1;
}

export function getDocumentTitleFromMarkdown(post) {
  const title =
    post?.content?.match(/^#\s(.*)/m)?.[1]?.replace(/\*|\`/g, '') ?? 'Untitled';
  return `${title} - TinyFrontend`;
}
