export function getQuestions(content) {
  try {
    return (
      JSON.parse(content)
        ?.map((question) => {
          return {
            title: question.title ?? '',
            companies: question.companies ?? [],
            tags: question.tags ?? [],
            difficulty: question.difficulty ?? 'easy',
          };
        })
        ?.filter((exist) => exist) ?? []
    );
  } catch (ex) {
    return [];
  }
}

export function getTasksPrompt(prompt) {
  return `This is a content about frontend interview questions, please analysis the content and finish the tasks.

[TASKS]
1. Your response format most compatible with "content-type: application/json".
2. Please pick out each of the interview question from the input.
3. Format the questions in the following output JSON format.
4. Because I will use the output as the input of my script, please return the JSON part only.

[CONTENT INPUT]
${prompt}

[OUTPUT JSON TEMPLATE]
{
    "tasks": [
        "REPLACE_WITH_CONTENT_OF_EACH_QUESTION"
    ]
}
`;
}

export function getQuestionPrompt(question: string) {
  return `You're an web frontend expert, please provide me the answer to the following interview question and fulfill the task demands:

Question:

${question}

Tasks:
1. help me to rewrite the question description.
2. provide the answer in 2 main sections: "Quick Answer" and "Explain in Depth".
3. for the "Quick Answer" section, please try provide a briefing answer in clean words.
4. for the "Explain in Depth" section, please include more details, better with demo code snippets.
5. for all the above output, please fill in this MarkDown format:

# Quiz

{REWRITED_QUESTION_DESCRIPTION}

## Quick Answer

{QUICK_ANSWER_CONTENT}

## Explain in Depth

{EXPLAIN_IN_DEPTH_CONTENT}
`;
}
