//
import React from 'react';
//
import * as monaco from 'monaco-editor/esm/vs/editor/editor.main';
// utils
import EditorAI from './EditorAI';
import { addAction } from './MenuAction';
import { openBrowserModal } from '../BrowserModal';
// styles
import DefaultTheme from './theme.json';
import './styles.scss';

monaco.editor.defineTheme('default-theme', DefaultTheme);

// react component
export default class CodeEditor extends React.PureComponent {
  static defaultProps = {
    onChange: () => {},
    onActiveLineChange: () => {},
  };
  ref = React.createRef();
  editor = null;

  constructor(props) {
    super(props);
    this.editorAI = new EditorAI({
      content: props.value,
    });
  }

  autoHeight = () => {
    if (this.props.autoHeight) {
      this.ref.current.style.height = `${
        this.editor.getContentHeight() + 10
      }px`;
    }
  };

  insertCode = (code) => {
    this.editor.trigger('keyboard', 'type', {
      text: code,
    });
  };

  render() {
    return <div ref={this.ref} className="code-editor"></div>;
  }

  componentDidMount(): void {
    this.editor = monaco.editor.create(this.ref.current, {
      value: this.props.value,
      language: this.props.language,
      fontSize: 16,
      fontFamily: 'monospace',
      minimap: {
        enabled: false,
      },
      lineNumbers: 'off',
      padding: {
        top: 16,
        bottom: 16,
      },
      scrollBeyondLastLine: false,
      scrollbar: {
        verticalScrollbarSize: '6px',
        alwaysConsumeMouseWheel: false,
      },
      wordWrap: 'on',
      theme: this.props.theme || 'default-theme',
      automaticLayout: true,
    });
    // add actions
    addAction(this.editor, {
      editorAI: this.editorAI,
      template: this.props.template,
      storage: this.props.storage,
      onSave: this.props.onSave,
    });
    // add event listeners
    this.editor.getContribution('editor.linkDetector').openerService.open = (
      url,
    ) => {
      openBrowserModal(url);
    };
    this.editor.onDidChangeModelContent((e) => {
      this.autoHeight();
      this.props.onChange(this.editor.getValue());
    });
    //
    this.editor.onDidChangeCursorPosition((e) => {
      this.props.onActiveLineChange(e.position.lineNumber);
    });
    //
    this.autoHeight();
    // auto focus
    this.editor.focus();
  }
}
