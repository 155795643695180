import React from 'react';
// utils
import { call, isAvailable } from 'Util/Agent';
import { getContentSlides } from 'Util/Post';
import { getPreviewURL } from './utils';
//
import './styles.scss';

function TemplateQuiz({ post }) {
  return (
    <iframe
      className="border-2 border-gray-100 bg-white w-full h-full"
      width="100%"
      height="100%"
      src={getPreviewURL(post)}
    />
  );
}

export default {
  id: 'quiz',
  title: 'Website Quiz',
  icon: 'help-circle',
  color: 'text-blue-500',
  onSave: async ({ post }) => {
    // run post deploy automatically
    if (post.slug) {
      await call(`/build/quiz/${post.id}`);
    }
  },
  renderToolbar: ({
    post,
    onChange,
    renderTextButton,
    renderColorButton,
    renderTextInput,
    renderDivider,
    renderImageButton,
    renderSelectButton,
  }) => {
    const previewURL = getPreviewURL(post);
    return (
      <>
        {renderColorButton({
          key: 'primaryColor',
          icon: 'type',
          className: 'w-32',
        })}
        {renderTextInput({
          key: 'tags',
          placeholder: 'Tag, Tag',
          icon: 'tag',
        })}
        {renderSelectButton({
          key: 'category',
          icon: 'folder',
          options: [
            { id: '', title: '[Category]' },
            { id: 'JavaScript', title: 'JavaScript' },
            { id: 'CSS', title: 'CSS' },
            { id: 'HTML', title: 'HTML' },
            { id: 'Accessibility', title: 'Accessibility' },
            { id: 'Internationalization', title: 'I18n' },
            { id: 'Performance', title: 'Performance' },
            { id: 'Network', title: 'Network' },
            { id: 'Security', title: 'Security' },
            { id: 'Testing', title: 'Testing' },
          ],
        })}
        {renderSelectButton({
          key: 'frequency',
          icon: 'bar-chart-2',
          options: [
            { id: '', title: '[Frequency]' },
            { id: 'High', title: 'High' },
            { id: 'Medium', title: 'Medium' },
            { id: 'Low', title: 'Low' },
          ],
        })}
        {renderDivider()}
        {renderTextInput({
          key: 'slug',
          placeholder: '0105',
          icon: 'hash',
        })}
        {renderImageButton({
          url: `https://quickchart.io/qr?text=${encodeURIComponent(
            previewURL,
          )}&margin=2&size=200`,
          onClick: () => {
            window.open(previewURL);
          },
        })}
      </>
    );
  },
  renderTemplate: (props) => {
    return <TemplateQuiz {...props} />;
  },
};
